<template lang="pug">
.quick
  h1  Multiple Payment
  AppCard
    AppFormInput(type="text" v-model:value="serviceNumber" :label="$t('quickpayment.service_number')" :disabled="loading")
    .text-center
    vue-recaptcha.g-recaptcha(:site-key="siteKey" v-if="!loading" size="normal" theme="light" @verify="verifyRecaptcha")
    .row
    AppButton.col-span-2(:disabled="bloquear" :loading="loading" :label="$t('quickpayment.continue')" @click="submitForm")
  ResultsMultiple(:key="key" :serviceNumber="serviceNumber" :hasError="hasError" v-model:invoices="list_to_be_paid" :service="service" :showOption="true")
  AppCard.mt-4.text-center
    span.label
      | {{$t('quickpayment.caption')}}
  AppCard.mt-4.text-center
    span.label
      | {{$t('quickpayment.info')}}
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { store } from "@/store";
import { axios } from "@/utils/axios";

import VueRecaptcha from "vue3-recaptcha2";
import AppCard from "@/components/AppCard";
import AppCardForm from "@/components/Card/AppCardForm";
import AppFormInput from "@/components/Form/AppFormInput";
import AppButton from "@/components/AppButton";
import ResultsMultiple from "@/views/Guest/QuickPayment/ResultsMultiple.vue";

export default {
  components: {
    AppCard,
    AppCardForm,
    AppFormInput,
    AppButton,
    VueRecaptcha,
    ResultsMultiple,
  },
  setup() {
    const route = useRoute();
    const invoices = ref([]);
    const service = ref({});
    const key = ref(0);
    const serviceNumber = ref("");
    const recaptcha = ref(false);
    const hasError = ref(false);
    const siteKey = process.env.VUE_APP_RECAPTCHA_KEY;
    const list_to_be_paid = ref([]);

    const verifyRecaptcha = (response) => {
      //   console.log("verify recaptcha", response);
      if (response != "") recaptcha.value = true;
    };

    const loading = computed(() => {
      return store.APP_LOADING;
    });

    const bloquear = computed(() => {
      return serviceNumber.value === "" || loading.value || !recaptcha.value;
    });

    const loadService = async (id) => {
      try {
        store.APP_LOADING = true;
        const { data } = await axios.get(`service/quick/${id.toUpperCase()}`);
        console.log(data);
        invoices.value = data.payload;
        list_to_be_paid.value = invoices.value
          .filter((invoice) => invoice.payment_status == false)
          .map((invoice) => ({
            ...invoice,
            state_to_pay: true,
          }));
        service.value = data.service[0];
        hasError.value = !invoices.value.length;
        // console.log(data.payload);
      } catch (error) {
        console.log(error);
      } finally {
        store.APP_LOADING = false;
        recaptcha.value = "";
      }
    };

    const submitForm = async () => {
      console.log("Submit..");
      await loadService(serviceNumber.value);
      key.value = Math.random() * 10;
    };

    onMounted(() => {
      serviceNumber.value = route?.params?.id || "";
      store.APP_LOADING = false;
    });

    return {
      serviceNumber,
      loading,
      verifyRecaptcha,
      bloquear,
      siteKey,
      submitForm,
      key,
      invoices,
      service,
      hasError,
      list_to_be_paid,
    };
  },
};
</script>

<style lang="scss" scoped>
.quick {
  @apply p-4;
  @apply mx-auto;
  @screen lg {
    @apply w-3/4;
  }
  @screen xl {
    @apply w-1/2;
  }
}

.g-recaptcha {
  @apply flex;
  @apply justify-center;
  @apply mb-1;
  @apply transform;
  @apply scale-75;
  @screen md {
    @apply scale-100;
  }
}

.label {
  @apply text-sm;
  @apply font-medium;
  @apply mb-2;
  @apply inline-block;
  @apply tracking-wide;
}
</style>
